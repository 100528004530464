<template>
  <div>
    <categoty-content></categoty-content>
  </div>
</template>

<script>
import CategotyContent from "@/views/home/components/CategotyContent";
export default {
  components: {
    CategotyContent,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #f6f8fc;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close,
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: @themeBlue;
}
</style>

<style lang="less">
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  box-sizing: border-box;
  max-width: 500px;
}
</style>
