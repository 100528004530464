var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-category-container"},[_c('div',{staticClass:"flex flex-m course-category-header"},[_c('el-image',{staticClass:"course-category-header-icon",attrs:{"src":_vm.item.titleIcon,"fit":"cover"}}),_vm._v(" "+_vm._s(_vm.item.cnName)+" ")],1),_c('div',{staticStyle:{"margin-top":"-9px"}},[_c('swiper',{ref:'mySwiper' + _vm.index,staticStyle:{"padding-top":"12px"},attrs:{"options":_vm.swiperOption}},_vm._l((_vm.item.swiperList),function(obj){return _c('swiper-slide',{key:obj.id,staticClass:"course-content"},_vm._l((obj.list),function(courseItem,i){return _c('div',{key:courseItem.id,staticClass:"course-item",on:{"click":function($event){return _vm.onCategoryItemClick(courseItem, i)}}},[_c('el-image',{staticClass:"course-item-cover",attrs:{"src":courseItem.coverUrl || _vm.defaultCategoryImage,"fit":"cover"}}),_c('div',{staticClass:"course-item-name over-flow-2"},[_vm._v(" "+_vm._s(courseItem.cnName)+" ")])],1)}),0)}),1),(_vm.item.swiperList && _vm.item.swiperList.length > 1)?_c('div',{staticClass:"swiper-arrow"},[_c('i',{staticClass:"el-icon-arrow-left arrow-btn",class:[
          'cc-left' + _vm.index,
          _vm.item.activeIndex ? '' : 'arrow-btn-disabled',
        ],staticStyle:{"margin-right":"36px"}}),_c('i',{staticClass:"el-icon-arrow-right arrow-btn",class:[
          'cc-right' + _vm.index,
          _vm.item.activeIndex === _vm.item.swiperList.length - 1
            ? 'arrow-btn-disabled'
            : '',
        ]})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }