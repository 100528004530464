<template>
  <div v-loading="allLoading" class="categoty-content">
    <div v-if="!canAccess || hasCategory === -1">
      <div class="no-auth">
        <div class="head">
          {{ $t("el.authDialog.applicationTitle") }}
        </div>
        <div class="auth-data">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="authList"
            border
            tooltip-effect="dark"
            style="width: 100%"
            align="center"
          >
            <el-table-column
              prop="staffName"
              :label="$t('el.authDialog.name')"
              show-overflow-tooltip
            ></el-table-column>
            <!-- <el-table-column
                  :label="$t('el.authDialog.subjects')"
                  show-overflow-tooltip
                  width="155"
                >
                  <template slot-scope="scope">
                    <span
                      v-for="(item, index) in scope.row.subjectAuthList"
                      :key="index"
                    >
                      {{ item.subjectName }}
                      <span v-if="index != scope.row.subjectAuthList.length - 1"
                        >、</span
                      >
                    </span>
                  </template>
                </el-table-column> -->
            <!-- <el-table-column
                  :label="$t('el.authDialog.grades')"
                  show-overflow-tooltip
                  width="280"
                >
                  <template slot-scope="scope">
                    <span
                      v-for="(item, index) in scope.row.gradeAuthList"
                      :key="index"
                    >
                      {{ item.gradeName }}
                      <span v-if="index != scope.row.gradeAuthList.length - 1"
                        >、</span
                      >
                    </span>
                  </template>
                </el-table-column> -->
            <el-table-column :label="$t('el.authDialog.courseNames')">
              <template slot-scope="scope">
                <tooltip-over
                  :content="
                    scope.row.authCourseNames &&
                    scope.row.authCourseNames.join('、')
                  "
                  :singleAble="true"
                ></tooltip-over>

                <!-- <span
                      v-for="(item, index) in scope.row.authCourseNames"
                      :key="index"
                    >
                      {{ item }}
                      <span v-if="index != scope.row.authCourseNames.length - 1"
                        >、</span
                      >
                    </span> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="mobile"
              :label="$t('el.authDialog.telephone')"
              show-overflow-tooltip
              width="150"
            ></el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <xm-pagination
            v-if="authList && authList.length && !canAccess"
            :total="pageInfoAuth.total"
            :params="pagingParamsAuth"
            @params-change="(params) => (pagingParamsAuth = params)"
          />
        </div>
      </div>
    </div>
    <div v-else-if="canAccess && hasCategory === 1">
      <div v-for="(item, index) in categotyList" :key="item.id">
        <course-categoty-item
          :item="item"
          :index="index + ''"
          @on-category-item-click="onCategoryItemClick"
        ></course-categoty-item>
      </div>
    </div>
    <div v-else-if="canAccess && hasCategory === 0" class="empty-view">
      <empty-view :emptyText="$t('el.home.noSuggestCourse')"></empty-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CourseCategotyItem from "@/views/home/components/CourseCategotyItem.vue";
import emptyView from "@/components/biz-components/emptyView/index.vue";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import { getCourseIndexCategoty } from "@/api/home";

export default {
  components: {
    CourseCategotyItem,
    emptyView,
    tooltipOver,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters(["regulator", "indexImageList", "canAccess"]),
  },
  data() {
    return {
      allLoading: false,
      categotyList: [],
      categoryIdList: [],
      hasCategory: -2,
      pagingParamsAuth: {
        limit: 5,
        offset: 0,
        page: 1,
        pages: 1,
      },
      authList: [],
      loading: false,
      pageInfoAuth: {
        total: 0,
      },
    };
  },
  async created() {
    if (this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect);
    } else {
      // this.getCourseList();
      if (this.canAccess) {
        await this.getCourseIndexCategoty();
        if (this.hasCategory === -1) {
          this.getAuthData();
        }
      } else {
        this.getAuthData();
      }
    }
  },
  methods: {
    // 获取课程类型
    getCourseIndexCategoty() {
      this.allLoading = true;
      return getCourseIndexCategoty()
        .then((result) => {
          this.allLoading = false;
          // console.log("getCourseIndexCategoty", result);
          this.hasCategory = result.data.hasCategory;
          let list =
            result.data && result.data.oneLevelCategory
              ? result.data.oneLevelCategory
              : [];
          list.forEach((element) => {
            // if (this.categoryIdList.indexOf(element.id) > -1) {
            //   element.titleIcon =
            //     this.imageList[
            //       this.categoryIdList.indexOf(element.id) %
            //         this.imageList.length
            //     ].titleIcon;
            // } else {
            let n = element.id % this.indexImageList.length;

            element.titleIcon = this.indexImageList[n].titleIcon;
            this.categoryIdList.push(element.id);
            // }
          });
          this.categotyList = list;
          console.log("this.categotyList", this.categotyList);
        })
        .catch(() => {
          this.allLoading = false;
          this.categotyList = [];
          this.hasCategory = 0;
        });
    },
    // 跳转课程列表
    onCategoryItemClick(item) {
      // console.log("item",item);
      this.$router.push({
        name: "courseList",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #f6f8fc;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close,
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: @themeBlue;
}
.empty-view {
  background: #ffffff;
  padding-bottom: 80px;
  border-radius: 10px;
}

.no-auth {
  width: 700px;
  margin: 0 auto;
  .head {
    font-size: 18px;
    color: rgba(100, 108, 149, 1);
    line-height: 24px;
    margin-top: 3px;
    margin-bottom: 25px;
    text-align: center;
  }
  .auth-data {
    margin-bottom: 38px;
  }
  /deep/ .el-tooltip__popper {
    position: absolute;
    border-radius: 4px;
    padding: 10px;
    z-index: 2000;
    font-size: 12px;
    line-height: 1.2;
    min-width: 10px;
    word-wrap: break-word;
    max-width: 500px;
    box-sizing: border-box;
  }
}
.categoty-content {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
</style>
<style lang="less">
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  box-sizing: border-box;
  max-width: 500px;
}
</style>
