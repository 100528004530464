<template>
  <div class="course-category-container">
    <div class="flex flex-m course-category-header">
      <el-image
        class="course-category-header-icon"
        :src="item.titleIcon"
        fit="cover"
      ></el-image>
      {{ item.cnName }}
    </div>
    <!-- <div
      v-if="item.secondLevelCategory.length < num + 1"
      style="padding-top:12px;margin-top:-9px;"
    >
      <div class="course-content">
        <div v-for="obj in item.secondLevelCategory" :key="obj.id" class="course-item">
          <el-image
            class="course-item-cover"
            :src="obj.coverUrl"
            fit="fit"
          ></el-image>
          <div class="course-item-name over-flow-2">{{obj.cnName}}</div>
        </div>
      </div>
    </div> -->
    <div style="margin-top: -9px">
      <swiper
        :ref="'mySwiper' + index"
        :options="swiperOption"
        style="padding-top: 12px"
      >
        <swiper-slide
          v-for="obj in item.swiperList"
          :key="obj.id"
          class="course-content"
        >
          <div
            v-for="(courseItem, i) in obj.list"
            :key="courseItem.id"
            class="course-item"
            @click="onCategoryItemClick(courseItem, i)"
          >
            <el-image
              class="course-item-cover"
              :src="courseItem.coverUrl || defaultCategoryImage"
              fit="cover"
            ></el-image>
            <div class="course-item-name over-flow-2">
              {{ courseItem.cnName }}
            </div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination">
            dfgdfgfdg
        </div> -->

        <!-- <div slot="pagination" class="swiper-pagination" /> -->
        <!-- <div slot="button-prev" class="swiper-button-prev" />
          <div slot="button-next" class="swiper-button-next" /> -->
      </swiper>
      <div
        class="swiper-arrow"
        v-if="item.swiperList && item.swiperList.length > 1"
      >
        <!-- v-show="item.activeIndex" -->
        <i
          :class="[
            'cc-left' + index,
            item.activeIndex ? '' : 'arrow-btn-disabled',
          ]"
          style="margin-right: 36px"
          class="el-icon-arrow-left arrow-btn"
        ></i>
        <i
          :class="[
            'cc-right' + index,
            item.activeIndex === item.swiperList.length - 1
              ? 'arrow-btn-disabled'
              : '',
          ]"
          class="el-icon-arrow-right arrow-btn"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    let self = this;
    return {
      num: 10,
      defaultCategoryImage: require("@/assets/images/icon/icon-default-category.png"), // 默认封面
      swiperOption: {
        loop: false,
        // width: this.pxTovw(1030),
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许分页点击跳转
        },
        navigation: {
          nextEl: ".cc-right" + this.index,
          prevEl: ".cc-left" + this.index,
        },
        on: {
          transitionEnd() {
            // console.log("swipe:", self.swiper.$swiper.activeIndex);
            let activeIndex = self.swiper.$swiper.activeIndex;
            self.$set(self.item, "activeIndex", activeIndex);
            // self.playbackItem.activeIndex = activeIndex;
          },
        },
      },
    };
  },
  props: {
    index: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  watch: {
    item: {
      handler(newVal) {
        // console.log("newVal",newVal);
        if (
          newVal &&
          newVal.secondLevelCategory &&
          newVal.secondLevelCategory.length > 0
        ) {
          let num = this.num;
          let n = Math.ceil(newVal.secondLevelCategory.length / num);
          // console.log("n:", n);
          newVal.swiperList = [];
          for (let index = 0; index < n; index++) {
            let a = {
              list: [],
            };
            newVal.secondLevelCategory.forEach((element, idx) => {
              if (index * num <= idx && idx < (index + 1) * num) {
                a.list.push(element);
              }
            });
            newVal.swiperList.push(a);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    swiper() {
      return this.$refs["mySwiper" + this.index];
    },
  },

  methods: {
    // 课程类别点击
    onCategoryItemClick(item, index) {
      this.$emit("on-category-item-click", item, index);
    },
  },
};
</script>

<style lang="less" scoped>
@mw: 1.174px;
@media screen and (min-width: 1620px) {
  .course-category-container {
    width: 100%;

    background: #ffffff;
    border-radius: 8 * @mw;
    margin-bottom: 20 * @mw;
    /deep/ .swiper-container {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: inherit;
      list-style: none;
      padding: 0;
      z-index: 1;
    }
    .course-category-header {
      height: 77 * @mw;
      margin-left: 26 * @mw;
      font-size: 20 * @mw;
      font-family: PingFang SC;
      font-weight: 500;
      color: #131313;
      &-icon {
        width: 36 * @mw;
        height: 28 * @mw;
        margin-right: 13 * @mw;
      }
    }
    .course-content {
      box-sizing: border-box;
      padding-left: 24 * @mw;
      padding-right: 24 * @mw;
      padding-bottom: 12 * @mw;
      display: grid;
      grid-template-columns: repeat(5, 20%);
      grid-template-rows: repeat(5, auto-fill);
      .course-item {
        width: 220 * @mw;
        background: #ffffff;
        border-radius: 6 * @mw;
        padding: 10 * @mw;
        box-sizing: border-box;
        box-shadow: 0 0 10 * @mw 11 * @mw #f3f3f3;
        margin-bottom: 21 * @mw;
        cursor: pointer;
        &-cover {
          width: 200 * @mw;
          height: 150 * @mw;
          border-radius: 6 * @mw;
        }
        &-name {
          width: 188 * @mw;
          font-size: 14 * @mw;
          font-family: PingFang SC;
          font-weight: 400;
          color: #131313;
          margin-top: 11 * @mw;
          margin-bottom: 12 * @mw;
          word-break: break-all;
        }
      }
    }
    .swiper-arrow {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 30 * @mw;
      .arrow-btn {
        padding: 10 * @mw;
        background: #5a53f5;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14 * @mw;
        cursor: pointer;
      }
      .arrow-btn-disabled {
        padding: 10 * @mw;
        background: #9590fd;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14 * @mw;
      }
    }
  }
}

@media screen and (max-width: 1619px) {
  .course-category-container {
    width: 100%;

    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
    /deep/ .swiper-container {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: inherit;
      list-style: none;
      padding: 0;
      z-index: 1;
    }
    .course-category-header {
      height: 77px;
      margin-left: 26px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #131313;
      &-icon {
        width: 36px;
        height: 28px;
        margin-right: 13px;
      }
    }
    .course-content {
      box-sizing: border-box;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 12px;
      display: grid;
      grid-template-columns: repeat(5, 20%);
      grid-template-rows: repeat(5, auto-fill);
      .course-item {
        width: 220px;
        background: #ffffff;
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 11px #f3f3f3;
        margin-bottom: 21px;
        cursor: pointer;
        &-cover {
          width: 200px;
          height: 150px;
          border-radius: 6px;
        }
        &-name {
          width: 188px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #131313;
          margin-top: 11px;
          margin-bottom: 12px;
          word-break: break-all;
        }
      }
    }
    .swiper-arrow {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 30px;
      .arrow-btn {
        padding: 10px;
        background: #5a53f5;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
      }
      .arrow-btn-disabled {
        padding: 10px;
        background: #9590fd;
        border-radius: 50%;
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
}
</style>
